<template>
  <v-card>
    <v-card-title class="justify-center">{{ $t('module.survey.step3.title') }}</v-card-title>
    <v-card-text>
      <v-row>

        <v-col cols="12" sm="12" v-if="user.statusGroup != 0">

          <v-row>

            <v-col cols="12">
              <p>Special Case: Only for persons who have changed from "doctoral researcher" status group to another status group since the last survey without indicating the date of conferral of a doctorate or the date on which the dissertation was submitted.</p>
              <v-checkbox v-model="user.wasPhd" label="Yes, i changed status group" />
            </v-col>


            <v-col v-if="user.wasPhd" cols="12">
              <p>Please state the status of your doctoral project</p>
              <v-radio-group :mandatory="false" v-model="user.wasPhdStatus">
                <v-radio
                    label="Dissertation submitted, oral examination pending"
                    value="0"
                />
                <v-radio
                    label="Dissertation submitted and oral examination or oral defense ('Disputation', 'Rigorosum', etc.) passed"
                    value="1"
                />
                <v-radio
                  label="Doctoral work discontinued"
                  value="2"
                />
                <v-radio
                  label="I don't know, not specified"
                  value="3"
                />
              </v-radio-group>
            </v-col>






            <v-col cols="12" v-if="user.wasPhdStatus == '0' || user.wasPhdStatus == '1'">
              <p>{{ $t('module.survey.step3.dissertationSubmit.title') }}</p>
            </v-col>
            <v-col cols="12" sm="6" v-if="user.wasPhdStatus == '0' || user.wasPhdStatus == '1'">
              <v-menu
                  :close-on-content-click="true"
                  max-width="290px"
                  min-width="290px"
                  offset-y
                  transition="scale-transition"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                      prepend-icon="mdi-calendar"
                      readonly
                      clearable
                      :disabled="user.dissertationFinishAnswer"
                      v-model="user.dissertationFinishDate"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    no-title
                    scrollable
                    type="month"
                    max="2023-09"
                    :disabled="user.dissertationFinishAnswer"
                    v-model="user.dissertationFinishDate"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="6" v-if="user.wasPhdStatus == '0' || user.wasPhdStatus == '1'">
              <v-checkbox
                  v-if="!user.dissertationFinishDate"
                  label="not (yet) completed / i don't know / not specified"
                  v-model="user.dissertationFinishAnswer"
              />
            </v-col>

            <v-col cols="12" v-if="user.wasPhdStatus == '1'">
              <p>{{ $t('module.survey.step3.oralExamination.title') }}</p>
            </v-col>
            <v-col cols="12" sm="6" v-if="user.wasPhdStatus == '1'">
              <v-menu
                  :close-on-content-click="true"
                  max-width="290px"
                  min-width="290px"
                  offset-y
                  transition="scale-transition"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                      prepend-icon="mdi-calendar"
                      readonly
                      clearable
                      :disabled="user.examAnswerNew"
                      v-model="user.examDate"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    no-title
                    scrollable
                    type="month"
                    v-model="user.examDate"
                    max="2023-09"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="6"  v-if="user.wasPhdStatus == '1'">
              <v-checkbox
                  v-if="!user.examDate"
                  label="not (yet) completed / i don't know / not specified"
                  v-model="user.examAnswerNew"
              />
            </v-col>
          </v-row>

        </v-col>

        <!-- Statusgruppe Doktorand*innen -->
        <v-col cols="12" sm="12" v-if="user.statusGroup == 0">
          <v-row>

            <v-col cols="12">
              <p>Please provide information on your university degree that qualifies you for a doctorate</p>
              <v-radio-group :mandatory="false" v-model="user.qualifiesDoctorate">
                <v-radio
                    label="I have been accepted to the doctoral programme as a graduate of a bachelor programme (Fast Track)"
                    value="0"
                />
                <v-radio
                    label="I have a master or similar degree - e.g. state examination, diploma"
                    value="1"
                />
              </v-radio-group>
            </v-col>

            <v-col cols="12">
              <p>Please state the date of the university degree that qualifies you for a doctorate.</p>
            </v-col>
            <v-col cols="12" sm="6">
              <v-menu
                  :close-on-content-click="true"
                  max-width="290px"
                  min-width="290px"
                  offset-y
                  transition="scale-transition"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                      prepend-icon="mdi-calendar"
                      readonly
                      :disabled="user.universityDegreeDateQualifiesDoctorate"
                      clearable
                      v-model="user.universityDegreeDateQualifiesDoctorateDate"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    no-title
                    scrollable
                    type="month"
                    :disabled="user.universityDegreeDateQualifiesDoctorate"
                    v-model="user.universityDegreeDateQualifiesDoctorateDate"
                    max="2023-09"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="6">
              <v-checkbox
                  v-if="!user.universityDegreeDateQualifiesDoctorateDate"
                  label="i don't know / not specified"
                  v-model="user.universityDegreeDateQualifiesDoctorate"
              />
            </v-col>



            <v-col cols="12" sm="12">
              <p>{{ $t('module.survey.step3.recentUniversityAtGermany.title') }}</p>
              <v-radio-group :mandatory="false" v-model="user.lastAcademicPlaceAnswer">
                <v-radio :label="$t('module.survey.step3.recentUniversityAtGermany.items')[0].label"
                         value="0"></v-radio>
                <v-radio :label="$t('module.survey.step3.recentUniversityAtGermany.items')[1].label"
                         value="1"></v-radio>
                <v-radio :label="$t('module.survey.step3.recentUniversityAtGermany.items')[2].label"
                         value="2"></v-radio>
              </v-radio-group>
              <v-divider></v-divider>
            </v-col>

            <v-col
              cols="12"
              sm="12"
              v-if="user.lastAcademicPlaceAnswer === '1'">
              <p>{{ $t('module.survey.step3.mostRecentDegreeOutsideGermany.title') }}</p>
              <v-text-field :label="$t('module.survey.step3.mostRecentDegreeOutsideGermany.label')"
                            v-model="user.lastAcademicPlace"></v-text-field>
              <v-divider></v-divider>
            </v-col>

            <v-col cols="12" sm="12" v-if="user.lastAcademicPlaceAnswer === '2'">
              <p>{{ $t('module.survey.step3.mostRecentDegreeInsideGermany.title') }}</p>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field v-model="user.lastAcademicPlace"
                                :label="$t('module.survey.step3.mostRecentDegreeInsideGermany.city.label')"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field v-model="user.lastAcademicPlaceInstitution"
                                :label="$t('module.survey.step3.mostRecentDegreeInsideGermany.institution.label')"></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-col>
            <v-col cols="12"
                   sm="12">
              <v-row>
                <v-col cols="12">
                  <p>{{ $t('module.survey.step3.doctoralWork.title') }}</p>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-menu
                    :close-on-content-click="true"
                    max-width="290px"
                    min-width="290px"
                    offset-y
                    transition="scale-transition"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        prepend-icon="mdi-calendar"
                        readonly
                        clearable
                        v-model="user.promotionBeginDate"
                        :disabled="user.promotionBeginAnswer"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      no-title
                      scrollable
                      type="month"
                      max="2023-09"
                      :disabled="user.promotionBeginAnswer"
                      v-model="user.promotionBeginDate"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-checkbox
                    v-if="!user.promotionBeginDate"
                    label="i don't know / not specified"
                    v-model="user.promotionBeginAnswer"
                  />
                </v-col>




                <v-col cols="12">
                  <p>Please state the status of your doctoral project.</p>
                  <v-radio-group :mandatory="false" v-model="user.doctoralProjectStatus">
                    <v-radio
                        label="Ongoing doctoral work, dissertation not (yet) submitted"
                        value="0"
                    />
                    <v-radio
                        label="Dissertation submitted, oral examination pending"
                        value="1"
                    />
                    <v-radio
                      label="Dissertation submitted and oral examination or oral defence (“Disputation”, “Rigorosum”, etc.) passed"
                      value="2"
                    />
                    <v-radio
                      label="Doctoral work discontinued"
                      value="3"
                    />
                    <v-radio
                      label="I don’t know / not specified"
                      value="4"
                    />
                  </v-radio-group>
                </v-col>




                <v-col cols="12" v-if="user.doctoralProjectStatus == '1' || user.doctoralProjectStatus == '2'">
                  <p>{{ $t('module.survey.step3.dissertationSubmit.title') }}</p>
                </v-col>
                <v-col cols="12" sm="6" v-if="user.doctoralProjectStatus == '1' || user.doctoralProjectStatus == '2'">
                  <v-menu
                    :close-on-content-click="true"
                    max-width="290px"
                    min-width="290px"
                    offset-y
                    transition="scale-transition"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        prepend-icon="mdi-calendar"
                        readonly
                        clearable
                        :disabled="user.dissertationFinishAnswer"
                        v-model="user.dissertationFinishDate"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      no-title
                      scrollable
                      type="month"
                      max="2023-09"
                      :disabled="user.dissertationFinishAnswer"
                      v-model="user.dissertationFinishDate"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6" v-if="user.doctoralProjectStatus == '1' || user.doctoralProjectStatus == '2'">
                  <v-checkbox
                    v-if="!user.dissertationFinishDate"
                    label="not (yet) completed / i don't know / not specified"
                    v-model="user.dissertationFinishAnswer"
                  />
                </v-col>

                <v-col cols="12" v-if="user.doctoralProjectStatus == '2'">
                  <p>{{ $t('module.survey.step3.oralExamination.title') }}</p>
                </v-col>
                <v-col cols="12" sm="6" v-if="user.doctoralProjectStatus == '2'">
                  <v-menu
                    :close-on-content-click="true"
                    max-width="290px"
                    min-width="290px"
                    offset-y
                    transition="scale-transition"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        prepend-icon="mdi-calendar"
                        readonly
                        clearable
                        :disabled="user.examAnswerNew"
                        v-model="user.examDate"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      no-title
                      scrollable
                      type="month"
                      v-model="user.examDate"
                      max="2023-09"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6"  v-if="user.doctoralProjectStatus == '2'">
                  <v-checkbox
                      v-if="!user.examDate"
                      label="not (yet) completed / i don't know / not specified"
                      v-model="user.examAnswerNew"
                  />
                </v-col>





                <v-col v-if="this.user.doctoralProjectStatus === '0' || this.user.doctoralProjectStatus === '4'" cols="12">

                  <div class="se2a-internal">
                    <div>Please state the envisaged date of submission of your dissertation (for SE²A internal purpose only)</div>

                    <v-menu
                        :close-on-content-click="true"
                        max-width="290px"
                        min-width="290px"
                        offset-y
                        transition="scale-transition"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                            prepend-icon="mdi-calendar"
                            readonly
                            clearable
                            v-model="user.dissertationSumbmissionInternal"
                            v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                          no-title
                          scrollable
                          type="month"
                          v-model="user.dissertationSumbmissionInternal"
                      >
                      </v-date-picker>
                    </v-menu>
                  </div>


                </v-col>








              </v-row>
            </v-col>

          </v-row>
        </v-col>

        <!-- Sonderfall: Letzte Erhebung Doktorand*innen -->
        <!-- TODO: Wird erst nächstes Jahr benötigt-->

        <!-- Weiteres wissenschaftliches Personal -->
        <v-col cols="12" sm="12">
          <v-row>

          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn :disabled="!readyToSend" @click="finish" class="mx-auto" color="primary">
        Send
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import {mapState} from "vuex";

  export default {
    name: "StepThree",
    props: {
      finish: {
        type: Function,
        required: true
      }
    },
    computed: {
      ...mapState('dfg', {
        user: 'activeSurvey'
      }),
      readyToSend() {
        if(this.user.statusGroup !== "0") {
          return !this.user.wasPhd ||
              (this.user.wasPhd && (
                  (this.user.wasPhdStatus === "0" && (this.user.dissertationFinishDate || this.user.dissertationFinishAnswer))
                  || (this.user.wasPhdStatus === "1" && (this.user.dissertationFinishDate || this.user.dissertationFinishAnswer) && (this.user.examDate || this.user.examAnswerNew))
                      || this.user.wasPhdStatus === "2" || this.user.wasPhdStatus === "3"
                  )
              );
        }

        return (this.user.universityDegreeDateQualifiesDoctorate || this.user.universityDegreeDateQualifiesDoctorateDate)
            && this.user.lastAcademicPlaceAnswer
          && (
            (this.user.lastAcademicPlaceAnswer === "0")
            || (this.user.lastAcademicPlaceAnswer === "1" && this.user.lastAcademicPlace)
            || (this.user.lastAcademicPlaceAnswer === "2" && this.user.lastAcademicPlace && this.user.lastAcademicPlaceInstitution)
          )
          && (this.user.promotionBeginDate || this.user.promotionBeginAnswer)
          && this.user.qualifiesDoctorate
          && (this.user.universityDegreeDateQualifiesDoctorateDate || this.user.universityDegreeDateQualifiesDoctorate)
          && (
                (this.user.doctoralProjectStatus === "0" && this.user.dissertationSumbmissionInternal)
                || (this.user.doctoralProjectStatus === "1" && (this.user.dissertationFinishDate || this.user.dissertationFinishAnswer))
                || (this.user.doctoralProjectStatus === "2" && (this.user.dissertationFinishDate || this.user.dissertationFinishAnswer) && (this.user.examDate || this.user.examAnswerNew))
                || this.user.doctoralProjectStatus === "3"
                || (this.user.doctoralProjectStatus === "4" && this.user.dissertationSumbmissionInternal)
            )
      }
    },
    data: () => {
      return {
        availableLastAcademicDegreeAnswers: [
          'Im',
          'Keine Angabe',
        ],
        availablePromotionDissertationBeginFinishAnswers: [
          'Im',
          'weiß nicht',
          'keine Angabe',
          '(noch) nicht erfolgt',
        ],
        availableExamAnswers: [
          'Im',
          'Noch nicht abgeschlossen',
          'abgebrochen',
          'keine Angabe',
        ],
      }
    }
  }
</script>

<style scoped>
.se2a-internal {
  padding: 8px;
  background: #bceefa;
}
</style>
